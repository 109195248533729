<template>
  <div class=" flex-grow">
    <div
      class="px-2 sm:px-0 relative rounded-lg    pb-0   to-gray-200   mt-0 sm:mt-6   "
    >
      <div class=" sm:mt-0 px-2 text-left font-semibold text-xl mb-6 sm:pl-12">
        A Taster Box crammed full of our <br class="block sm:hidden" />
        extremely tasty food.
      </div>
      <div
        class="flex absolute bottom-0 mb-4 right-0 -mr-2  sm:hidden justify-end z-10 w-56 "
      >
        <img :src="'/images/taster-pack.webp'" class="object-contain h-full " />
      </div>
      <div class=" relative z-20 pb-1 px-2 sm:px-0">
        <div class=" w-3/5 sm:w-3/5 sm:pl-12">
          Try
          <span class="font-bold border-b-2 bg-teal-100 p-1 border-teal-400"
            >all</span
          >
          our different Pouch, Tray and Can textures to
          <br class="flex sm:hidden" />
          find which they like…
        </div>
        <div class="w-3/5 sm:w-3/5 sm:pl-12 mt-4 space-y-2 pb-4  sm:pb-0">
          <div>
            4 x Chicken Recipes
          </div>
          <div>
            4 x Fish Recipes
          </div>
          <div>
            1 x Chicken Dry Food
          </div>
        </div>
        <div class="w-3/5 sm:w-3/5 sm:pl-12 text-sm text-gray-700 sm:mt-4 ">
          <font-awesome-icon class="mr-1" :icon="['fal', 'truck']" />
          Includes FREE Delivery!
        </div>
      </div>

      <div
        class="flex absolute bottom-0 right-0 -mb-8  -ml-32 h-ful  hidden flex-none sm:block justify-end z-10 h-56"
      >
        <img :src="'/images/taster-pack.webp'" class="object-contain h-full " />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="css" scoped></style>
