<template>
  <div class="mb-4 space-y-2 border-b-2 border-t-2 p-6 pb-4 pt-4">
    <div class="text-center font-semibold">
      {{ position === 0 ? 'Your' : 'Select your' }} Taster Box...
    </div>
    <div class="text-center text-sm text-gray-700">
      <font-awesome-icon class="mr-1" :icon="['fal', 'truck']" /> Includes FREE
      Delivery!
    </div>
    <div class="-mx-4 rounded-b-lg p-2 pt-2 text-gray-700">
      <div class="space-y-2">
        <div
          v-for="taster in availableTasters"
          :key="taster.key"
          @click="setTaster(taster.key)"
        >
          <div
            class="rounded border-2 p-3 py-2"
            :class="
              taster.key === selectedTaster
                ? 'border-teal-400 bg-teal-400 text-white'
                : 'cursor-pointer border-2 border-gray-300 hover:bg-gray-100'
            "
          >
            <div class="-ml-1 mb-1 inline-flex" v-if="taster.popular">
              <div
                class="flex h-5 items-center rounded bg-yellow-200 px-1 text-xs text-gray-800"
              >
                <font-awesome-icon
                  class="mr-1"
                  :icon="['far', 'stars']"
                />Popular
              </div>
            </div>
            <div class="flex space-x-2">
              <div class="flex flex-grow items-center space-x-2">
                <div class="mb-1 font-medium">{{ taster.name }}</div>
              </div>
              <div
                v-if="taster.discount"
                class="text-gray-400 line-through"
                :class="taster.key === selectedTaster ? 'text-teal-200' : ' '"
              >
                {{ taster.discount | formatPounds }}
              </div>
              <div v-if="taster.key !== 'none'">
                {{ taster.cost | formatPounds }}
              </div>
            </div>
            <div v-if="taster.description">
              <div
                v-for="(item, index) in taster.description[activeDescription]"
                :key="`${item}-${index}-description`"
                class="text-sm"
                :class="
                  taster.key === selectedTaster
                    ? 'text-teal-100'
                    : 'text-gray-700'
                "
              >
                - {{ item }}
              </div>
              <div
                v-if="
                  taster.key === 'fish' && taster.key === cat.sampleboxtype.type
                "
                class="mt-4 text-xs italic text-teal-100"
              >
                * A couple of our fish recipes also contain chicken. If your cat
                has allergies please get in touch.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'ChooseTaster',
  props: {
    cat: {
      type: Object
    },
    catLength: {
      type: Number,
      default: 1
    },
    position: {
      type: Number
    }
  },
  data() {
    return {
      selectedTaster: this.cat.sampleboxtype.type
    };
  },
  computed: {
    ...mapGetters({
      tasterBoxes: 'availableTasters',
      hasCosts: 'hasTastersCosts'
    }),
    activeDescription() {
      return this.cat.sampleboxtype.dryfood ? 'dry' : 'noDry';
    },
    availableTasters() {
      // if kitten, only show kitten taster or none

      if (this.cat.isKitten) {
        let tasters = [...this.tasterBoxes];
        if (this.catLength === 1 || this.position === 0) {
          tasters = tasters.filter((taster) => taster.key !== 'none');
        }
        // filter the array to only show ones with original and none keys
        return tasters.filter((taster) => taster.type === 'kitten');
      } else {
        // order the array by chicken, fish and both
        const tasters = [...this.tasterBoxes].filter(
          (taster) => taster.type !== 'kitten'
        );
        if (this.catLength === 1 || this.position === 0) {
          /// filter out the None option

          return tasters.filter((taster) => taster.key !== 'none');
        }

        return tasters;
      }
    },
    catClone() {
      return this.cat ? JSON.parse(JSON.stringify(this.cat)) : [];
    }
  },
  watch: {
    catClone: {
      deep: true,
      handler(value, oldValue) {
        if (value.isKitten) {
          if (value.isKitten !== oldValue.isKitten) {
            this.selectedTaster = 'original';
            this.cat.sampleboxtype.type = 'original';
          }
        } else {
          if (value.isKitten !== oldValue.isKitten) {
            this.selectedTaster = 'original';
            this.cat.sampleboxtype.type = 'original';
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getTasterBoxes: 'getTasterBoxesCost'
    }),
    ...mapMutations({
      updateTasters: 'update_tasters'
    }),
    setTaster(taster) {
      this.selectedTaster = taster;
      this.$emit('taster-selected', taster);
    }
  },
  mounted() {
    if (!this.hasCosts) {
      this.getTasterBoxes().then((resp) => {
        this.updateTasters(resp);
        this.selectedTaster = this.cat.sampleboxtype.type;
      });
    }
  }
};
</script>
