<template>
  <div>
    <div class="text-xl font-semibold mb-8">Your Cats</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped></style>
