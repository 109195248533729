<template>
  <div
    class="default-transition default-animation cat-card w-full rounded-lg border-2 bg-white sm:m-2"
    :class="{ 'border-gray-500': selectedCat === position }"
  >
    <div
      class="default-transition"
      :class="{ 'cursor-pointer hover:bg-gray-100': selectedCat != position }"
    >
      <div
        @click="selectCat(position)"
        class="relative flex items-center justify-between p-4 py-5 text-lg font-medium sm:px-6"
      >
        <transition
          enter-active-class="miniFadeInTop"
          leave-active-class="miniFadeOutBottom"
          mode="out-in"
        >
          <div key="noname" v-if="!cat.name">Cool Cat {{ position + 1 }}</div>
          <div key="hasname" v-else class="mr-4 flex-1 truncate">
            {{ cat.name }}
          </div>
        </transition>
        <div class="relative flex items-center">
          <div
            v-if="selectedCat != position"
            class="mr-4 font-sans text-base font-normal text-gray-500 hover:text-gray-600 hover:underline"
          >
            Update
          </div>
          <div
            v-if="selectedCat === position && numCats > 1"
            @click="removeCat(position)"
            class="mr-4 font-sans text-base font-normal text-gray-500 hover:text-gray-600 hover:underline"
          >
            <font-awesome-icon
              class="mr-3 mt-1"
              :icon="['far', 'trash-can-xmark']"
            />
          </div>
          <div
            class="default-transition w-8"
            :class="{
              'rotate-12': selectedCat === position,
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 62.55 64.55"
              class="text-teal-400"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path
                    class="avatar-bg"
                    d="M59.53,52.21c1.95-2.87,7.2-42.69-3.47-48.55C50.8.77,34.39,14.06,34.39,14.06S18.79-1.93,12.72.19C-1.47,5.14-4.21,49.62,6.65,60,16.31,69.24,52,63.25,59.53,52.21Z"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>

      <div
        v-if="selectedCat === position"
        class="mx-auto px-4 py-4 pb-0 sm:px-6"
      >
        <div class="input-group mx-auto">
          <label>What’s your name cool cat?</label>
          <input
            required
            v-model.lazy="cat.name"
            type="text"
            :ref="'name-' + position"
            placeholder="Mr Wiggles"
          />
        </div>
        <div class="input-group mx-auto">
          <label>Are you an adult cat or kitten?</label>
          <div class="flex justify-center text-gray-700">
            <div class="flex w-full space-x-2 rounded-lg bg-gray-100 p-2">
              <div
                @click="cat.isKitten = false"
                class="relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300"
                :class="{
                  'cursor-default bg-teal-400 text-white hover:bg-teal-400':
                    !cat.isKitten,
                }"
              >
                <div
                  class="flex h-6 w-6 flex-none items-center justify-center rounded-full bg-white"
                  v-if="!cat.isKitten"
                >
                  <font-awesome-icon
                    class="text-teal-500"
                    v-if="!cat.isKitten"
                    :icon="['far', 'check']"
                  />
                </div>
                <div class="mx-auto">Adult</div>
              </div>
              <div
                @click="cat.isKitten = true"
                class="relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300"
                :class="{
                  'cursor-default bg-teal-400 text-white hover:bg-teal-400':
                    cat.isKitten,
                }"
              >
                <div
                  class="flex h-6 w-6 flex-none items-center justify-center rounded-full bg-white"
                  v-if="cat.isKitten"
                >
                  <font-awesome-icon
                    class="text-teal-500"
                    v-if="cat.isKitten"
                    :icon="['far', 'check']"
                  />
                </div>
                <div class="mx-auto">Kitten</div>
              </div>
            </div>
          </div>
        </div>

        <div class="input-group mx-auto">
          <label>What gender are you?</label>
          <div class="relative block">
            <div
              class="pointer-events-none absolute right-0 p-3 pr-4 text-lg text-gray-700"
            >
              <font-awesome-icon :icon="['far', 'chevron-down']" />
            </div>
            <select v-model="cat.gender">
              <option value="" class="text-gray-400" disabled selected>
                Select an option
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Rather not say</option>
            </select>
          </div>
        </div>
        <!-- <div class="input-group mx-auto">
          <label>What tribe do you belong to?</label>
          <input
            required
            v-model="cat.tribe"
            type="text"
            placeholder="e.g. Ginger Tabby or Maine Coon"
          />
        </div> -->
        <div class="input-group mx-auto" v-if="!notTaster">
          <label>Do you eat dry food?</label>
          <div class="flex justify-center text-gray-700">
            <div class="flex w-full space-x-2 rounded-lg bg-gray-100 p-2">
              <div
                @click="cat.sampleboxtype.dryfood = true"
                class="relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300"
                :class="{
                  'cursor-default bg-teal-400 text-white hover:bg-teal-400':
                    cat.sampleboxtype.dryfood,
                }"
              >
                <div
                  class="flex h-6 w-6 flex-none items-center justify-center rounded-full bg-white"
                  v-if="cat.sampleboxtype.dryfood"
                >
                  <font-awesome-icon
                    class="text-teal-500"
                    v-if="cat.sampleboxtype.dryfood"
                    :icon="['far', 'check']"
                  />
                </div>
                <div class="mx-auto">Yes</div>
              </div>
              <div
                @click="cat.sampleboxtype.dryfood = false"
                class="relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300"
                :class="{
                  'cursor-default bg-teal-400 text-white hover:bg-teal-400':
                    !cat.sampleboxtype.dryfood,
                }"
              >
                <div
                  class="flex h-6 w-6 flex-none items-center justify-center rounded-full bg-white"
                  v-if="!cat.sampleboxtype.dryfood"
                >
                  <font-awesome-icon
                    class="text-teal-500"
                    v-if="!cat.sampleboxtype.dryfood"
                    :icon="['far', 'check']"
                  />
                </div>
                <div class="mx-auto">No</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="selectedCat === position && !notTaster"
          class="-mx-4 sm:-mx-6"
        >
          <choose-taster
            :cat="cat"
            :cat-length="numCats"
            :position="position"
            @taster-selected="setTaster"
          />
        </div>
        <div class="input-group mx-auto">
          <label v-if="isTaster || !isLoggedIn"
            >If {{ cat.name ? cat.name : `Cool Cat ${position + 1}` }} likes the
            food, how much would you like to receive every month?</label
          >
          <label v-else>How much would you like to receive every month?</label>

          <div
            class="-ml-4 -mr-4 mt-4 flex flex-col bg-gray-100 text-gray-700 sm:-ml-6 sm:-mr-6"
          >
            <div class="mt-2 text-center text-xs text-gray-600">
              Select an option:
            </div>
            <div class="flex w-full space-x-2 p-2 px-2 sm:px-2">
              <div
                @click="cat.foodAmount = 30"
                class="flex h-36 w-1/3 cursor-pointer flex-col justify-center rounded px-1 py-8 pb-3 text-center hover:bg-gray-300"
                :class="cat.foodAmount === 30 ? getSelectedClasses() : ''"
              >
                <div class="text-4xl font-semibold">30</div>
                <div class="text-xs">Pouches</div>
                <div
                  class="text-xl"
                  :class="
                    cat.foodAmount === 30 ? 'text-teal-100' : 'text-gray-600'
                  "
                >
                  &middot;
                </div>
                <div
                  class="text-xs"
                  :class="
                    cat.foodAmount === 30 ? 'text-teal-100' : 'text-gray-600'
                  "
                >
                  <span class="font-semibold">{{ getPricePerMeal(30) }}p</span>
                  <span> per meal</span>
                </div>
              </div>
              <div
                @click="cat.foodAmount = 60"
                class="relative flex h-36 w-1/3 cursor-pointer flex-col justify-center rounded px-1 py-8 pb-3 text-center hover:bg-gray-300"
                :class="cat.foodAmount === 60 ? getSelectedClasses() : ''"
              >
                <div
                  class="absolute left-0 top-0 flex w-full justify-center text-xs"
                >
                  <div
                    class="label-y flex w-full items-center justify-center rounded px-1"
                    :class="
                      cat.foodAmount === 60 ? getLabelClass() : 'text-gray-600'
                    "
                  >
                    <font-awesome-icon
                      class="mr-1"
                      :icon="['far', 'stars']"
                    />Popular
                  </div>
                </div>
                <div class="text-4xl font-semibold">60</div>
                <div class="text-xs">Pouches</div>
                <div
                  class="text-xl"
                  :class="
                    cat.foodAmount === 60 ? 'text-teal-100' : 'text-gray-600'
                  "
                >
                  &middot;
                </div>
                <div
                  class="text-xs"
                  :class="
                    cat.foodAmount === 60 ? 'text-teal-100' : 'text-gray-600'
                  "
                >
                  <span class="font-semibold">{{ getPricePerMeal(60) }}p</span>
                  <span> per meal</span>
                </div>
              </div>
              <div
                @click="cat.foodAmount = 90"
                class="flex h-36 w-1/3 cursor-pointer flex-col justify-center rounded px-1 py-8 pb-3 text-center hover:bg-gray-300"
                :class="cat.foodAmount === 90 ? getSelectedClasses() : ''"
              >
                <div class="text-4xl font-semibold">90</div>
                <div class="text-xs">Pouches</div>
                <div
                  class="text-xl"
                  :class="
                    cat.foodAmount === 90 ? 'text-teal-100' : 'text-gray-600'
                  "
                >
                  &middot;
                </div>
                <div
                  class="text-xs"
                  :class="
                    cat.foodAmount === 90 ? 'text-teal-100' : 'text-gray-600'
                  "
                >
                  <span class="font-semibold">{{ getPricePerMeal(90) }}p</span>
                  <span> per meal</span>
                </div>
              </div>
            </div>
            <div class="mb-3 mt-1 text-center text-sm italic">
              Easily adjust this anytime!
            </div>
          </div>

          <div
            class="mt-4 flex items-start space-x-4 text-sm"
            v-if="isTaster || !isLoggedIn"
          >
            <div class="space-y-1">
              <div
                class="flex items-center justify-center space-x-4 text-gray-800"
              >
                <!-- <span class="mb-2 text-center"
                  >Easily adjust this anytime!</span
                > -->
              </div>
              <div class="text-gray-600">
                The above includes a mixed selection of pouches. It's
                <span class="border-b-2 border-teal-400 font-semibold"
                  >really easy</span
                >
                to select different food, adjust quantities or add dry food at
                any time in the next 14 days! You can also cancel anytime.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChooseTaster from "./ChooseTaster.vue";
export default {
  components: { ChooseTaster },
  props: ["position", "selectedCat", "cat", "isFull", "numCats", "notTaster"],
  data() {
    return {
      colors: ["teal", "blue", "pink"],
    };
  },
  computed: {
    ...mapGetters({
      isTaster: "getIsTaster",
      corePrices: "corePricesGetter",
      isLoggedIn: "isLoggedIn",
    }),
  },
  created() {
    this.$on(`has-values-${this.position}`, this.updateValues);
  },
  methods: {
    updateDate: function (value) {
      this.cat.dob = value;
    },
    getSelectedClasses() {
      return `bg-teal-400 text-white hover:bg-teal-400  cursor-default`;
    },
    getLabelClass() {
      return "bg-teal-400";
    },
    selectCat: function (i) {
      this.$emit("selected-cat", i);
    },
    removeCat(i) {
      this.$emit("remove-cat", i);
    },
    getColor: function (type) {
      if (type === "border") {
        if (this.selectedCat === this.position) {
          return "border-" + this.colors[this.position] + "-400";
        }
      }

      if (type === "background") {
        if (this.selectedCat === this.position) {
          return "bg-" + this.colors[this.position] + "-400";
        }
      }
      if (type === "avatar") {
        return "text-" + this.colors[this.position] + "-400";
      }
    },
    getPricePerMeal(amount) {
      const data = this.corePrices.find((p) => p.amount === amount);

      return Math.round(data.value / amount);
    },
    updateValues: function (val) {
      this.cat.dryFood = val.dryValue;
      this.cat.wetFood = val.wetValue;
    },
    setTaster(taster) {
      if (taster === "none") {
        this.cat.samplebox = false;
        this.cat.sampleboxtype.type = "none";
        return;
      } else {
        if (!this.cat.sampleboxtype) {
          this.cat.sampleboxtype = {};
        }
        this.cat.samplebox = true;
        this.cat.sampleboxtype.type = taster;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {});
  },
};
</script>

<style lang="scss" scoped>
.avatar-bg {
  @apply fill-current;
}
.rotate-12 {
  transform: rotate(-12deg);
}

.cat-card {
  max-width: 400px;
  width: 100%;
}
.label-y {
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>
