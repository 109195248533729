var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4 space-y-2 border-b-2 border-t-2 p-6 pb-4 pt-4"},[_c('div',{staticClass:"text-center font-semibold"},[_vm._v(" "+_vm._s(_vm.position === 0 ? 'Your' : 'Select your')+" Taster Box... ")]),_c('div',{staticClass:"text-center text-sm text-gray-700"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'truck']}}),_vm._v(" Includes FREE Delivery! ")],1),_c('div',{staticClass:"-mx-4 rounded-b-lg p-2 pt-2 text-gray-700"},[_c('div',{staticClass:"space-y-2"},_vm._l((_vm.availableTasters),function(taster){return _c('div',{key:taster.key,on:{"click":function($event){return _vm.setTaster(taster.key)}}},[_c('div',{staticClass:"rounded border-2 p-3 py-2",class:taster.key === _vm.selectedTaster
              ? 'border-teal-400 bg-teal-400 text-white'
              : 'cursor-pointer border-2 border-gray-300 hover:bg-gray-100'},[(taster.popular)?_c('div',{staticClass:"-ml-1 mb-1 inline-flex"},[_c('div',{staticClass:"flex h-5 items-center rounded bg-yellow-200 px-1 text-xs text-gray-800"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['far', 'stars']}}),_vm._v("Popular ")],1)]):_vm._e(),_c('div',{staticClass:"flex space-x-2"},[_c('div',{staticClass:"flex flex-grow items-center space-x-2"},[_c('div',{staticClass:"mb-1 font-medium"},[_vm._v(_vm._s(taster.name))])]),(taster.discount)?_c('div',{staticClass:"text-gray-400 line-through",class:taster.key === _vm.selectedTaster ? 'text-teal-200' : ' '},[_vm._v(" "+_vm._s(_vm._f("formatPounds")(taster.discount))+" ")]):_vm._e(),(taster.key !== 'none')?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatPounds")(taster.cost))+" ")]):_vm._e()]),(taster.description)?_c('div',[_vm._l((taster.description[_vm.activeDescription]),function(item,index){return _c('div',{key:`${item}-${index}-description`,staticClass:"text-sm",class:taster.key === _vm.selectedTaster
                  ? 'text-teal-100'
                  : 'text-gray-700'},[_vm._v(" - "+_vm._s(item)+" ")])}),(
                taster.key === 'fish' && taster.key === _vm.cat.sampleboxtype.type
              )?_c('div',{staticClass:"mt-4 text-xs italic text-teal-100"},[_vm._v(" * A couple of our fish recipes also contain chicken. If your cat has allergies please get in touch. ")]):_vm._e()],2):_vm._e()])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }